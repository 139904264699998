<template>
  <div class="login uk-cover-container uk-background-secondary uk-flex uk-flex-center uk-flex-middle uk-height-viewport uk-overflow-hidden uk-light" data-uk-height-viewport>
	
		<div class="uk-width-medium uk-padding-small uk-position-z-index" uk-scrollspy="cls: uk-animation-fade">
			
			<div class="uk-text-center uk-margin">
				<h2 >Cannonsoft Login</h2>
			</div>

			<!-- login -->
			<form class="toggle-class" id="form">
				<fieldset class="uk-fieldset">
					<div class="uk-margin-small">
						<div class="uk-inline uk-width-1-1">
							<span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: user"></span>
							<input v-model="mail" class="uk-input uk-border-pill" required placeholder="Correo registrado" type="text">
						</div>
					</div>
					<div class="uk-margin-small">
						<div class="uk-inline uk-width-1-1">
							<span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: lock"></span>
							<input v-model="pass" class="uk-input uk-border-pill" required placeholder="Contraseña" type="password">
						</div>
					</div>
					<!-- <div class="uk-margin-small">
						<label><input class="uk-checkbox" type="checkbox"> Keep me logged in</label>
					</div> -->
					<div class="uk-margin-bottom">
						<button type="submit" class="uk-button uk-button-primary uk-border-pill uk-width-1-1">LOG IN</button>
					</div>
				</fieldset>
			</form>
			<!-- /login -->

			<!-- recover password -->
			<form class="toggle-class" hidden>
                <p class="uk-text-left">
                    Se envirá un correo con las instrucciones
                </p>
				<div class="uk-margin-small">
					<div class="uk-inline uk-width-1-1">
						<span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: mail"></span>
						<input v-model="mail" class="uk-input uk-border-pill" placeholder="Correo registrado" required type="text">
					</div>
				</div>
				<div class="uk-margin-bottom">
					<button @click="reset" class="uk-button uk-button-primary uk-border-pill uk-width-1-1">ENVIAR CORREO</button>
				</div>
			</form>
			<!-- /recover password -->
			
			<!-- action buttons -->
			<div>
				<div class="uk-text-center">
					<a class="uk-link-reset uk-text-small toggle-class" data-uk-toggle="target: .toggle-class ;animation: uk-animation-fade">¿Olvidaste tu contraseña?</a>
					<a class="uk-link-reset uk-text-small toggle-class" data-uk-toggle="target: .toggle-class ;animation: uk-animation-fade" hidden><span data-uk-icon="arrow-left"></span> Regresar</a>
				</div>
			</div>
			<!-- action buttons -->
            <hr>
            <a class="uk-link-reset uk-text-small uk-text-center" href="/register">¿Aún no tienes cuenta? Regístrate ahora</a>
		</div>

  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
export default {
    setup(){
        const mail = ref('')
        const pass = ref('')
        const loading = ref(false)


        const router = useRouter()
        


        const autologin = () => {
            if(firebase.auth().currentUser === null){
                return
            } else {
                router.push('/licences')
            }
        }
	

        firebase.auth().onAuthStateChanged(function(user) {
            autologin()
        });





        onMounted(()=>{
                window.scroll(0,0)
            autologin()
            document.getElementById('form').addEventListener('submit',(e)=>{
                e.preventDefault()
                loading.value = true
                firebase.auth().signInWithEmailAndPassword(mail.value, pass.value)
                .then((userCredential) => {
                    // Signed in
                    router.push('/licences')
                    // ...
                })
                .catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    UIkit.notification(errorCode + ': ' + errorMessage, 'danger')
                    loading.value = false
                });
            })
        })

        const reset = function () {

            if(!mail.value){
                UIkit.notification('Escribe el correo asociado a tu cuenta', 'warning')
                return
            }

            loading.value = true
            
            firebase.auth().sendPasswordResetEmail(mail.value)
            .then(function() {
            // Password reset email sent.
                    UIkit.notification('Se ha enviado el correo', 'success')
                    loading.value = false
            })
            .catch(function(error) {
            // Error occurred. Inspect error.code.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    UIkit.notification(errorCode + ': ' + errorMessage, 'danger')
                    loading.value = false
            });
        }

        return {
            mail,
            pass,
            loading,
            reset,
            router
        }
    }
}
</script>

<style>

</style>